import * as CookieConsent from 'vanilla-cookieconsent'

const CAT_NECESSARY = "necessary";
const CAT_ANALYTICS = "analytics";
const CAT_ADVERTISEMENT = "advertisement";
const CAT_FUNCTIONALITY = "functionality";
const CAT_SECURITY = "security";

const SERVICE_AD_STORAGE = 'ad_storage'
const SERVICE_AD_USER_DATA = 'ad_user_data'
const SERVICE_AD_PERSONALIZATION = 'ad_personalization'
const SERVICE_ANALYTICS_STORAGE = 'analytics_storage'
const SERVICE_FUNCTIONALITY_STORAGE = 'functionality_storage'
const SERVICE_PERSONALIZATION_STORAGE = 'personalization_storage'
const SERVICE_SECURITY_STORAGE = 'security_storage'

// Define dataLayer and the gtag function.
window.dataLayer = window.dataLayer || [];
function gtag(){window.dataLayer.push(arguments);}

/** 
 * Update gtag consent according to the users choices made in CookieConsent UI
 */
function updateGtagConsent() {
    gtag('consent', 'update', {
        [SERVICE_ANALYTICS_STORAGE]: CookieConsent.acceptedService(SERVICE_ANALYTICS_STORAGE, CAT_ANALYTICS) ? 'granted' : 'denied',
        [SERVICE_AD_STORAGE]: CookieConsent.acceptedService(SERVICE_AD_STORAGE, CAT_ADVERTISEMENT) ? 'granted' : 'denied',
        [SERVICE_AD_USER_DATA]: CookieConsent.acceptedService(SERVICE_AD_USER_DATA, CAT_ADVERTISEMENT) ? 'granted' : 'denied',
        [SERVICE_AD_PERSONALIZATION]: CookieConsent.acceptedService(SERVICE_AD_PERSONALIZATION, CAT_ADVERTISEMENT) ? 'granted' : 'denied',
        [SERVICE_FUNCTIONALITY_STORAGE]: CookieConsent.acceptedService(SERVICE_FUNCTIONALITY_STORAGE, CAT_FUNCTIONALITY) ? 'granted' : 'denied',
        [SERVICE_PERSONALIZATION_STORAGE]: CookieConsent.acceptedService(SERVICE_PERSONALIZATION_STORAGE, CAT_FUNCTIONALITY) ? 'granted' : 'denied',
        [SERVICE_SECURITY_STORAGE]: CookieConsent.acceptedService(SERVICE_SECURITY_STORAGE, CAT_SECURITY) ? 'granted' : 'denied',
    });
}

const cookieConsentGUIOptions = {
    consentModal: {
        layout: 'box',
        position: 'bottom right',
        equalWeightButtons: false,
        flipButtons: false
    },
    preferencesModal: {
        layout: 'box',
        position: 'right',
        equalWeightButtons: true,
        flipButtons: false
    }
}

const cookieConsentCategories = {
    [CAT_NECESSARY]: {
        enabled: true,  // this category is enabled by default
        readOnly: true,  // this category cannot be disabled
    },
    [CAT_ANALYTICS]: {
        autoClear: {
            cookies: [
                { name: /^_ga/ },
                { name: '_gid' }
            ]
        },
        services: {
            [SERVICE_ANALYTICS_STORAGE]: {
                label: 'Speichern von Informationen (Cookies) für Analytics, zB Besuchsdauer oder Einstiegsseiten.',
            }
        }
    },
    [CAT_ADVERTISEMENT]: {
        services: {
            [SERVICE_AD_STORAGE]: {
                label: 'Speichern von Informationen (Cookies) für Werbungszwecke, zB Google Ads'
            },
            [SERVICE_AD_USER_DATA]: {
                label: 'Senden von anonymisierten Userdaten zu Werbungszwecken an Google.'
            },
            [SERVICE_AD_PERSONALIZATION]: {
                label: 'Speichern von Einstellungen zu personalisierter Werbung.'
            }
        }
    },
    [CAT_FUNCTIONALITY]: {
        services: {
            [SERVICE_FUNCTIONALITY_STORAGE]: {
                label: 'Speichern von funktionalen Informationen zum Betrieb der Website, zB. Spracheinstellung.',
            },
            [SERVICE_PERSONALIZATION_STORAGE]: {
                label: 'Speicher von funktionalen Informationen zur Personalisierung, wie zB. Videoempfehlungen.',
            },
        }
    },
    [CAT_SECURITY]: {
        services: {
            [SERVICE_SECURITY_STORAGE]: {
                label: 'Speichern von sicherheitskritischen Informationen, zB. zur Authentifizierung und Bot-Prevention.',
            },
        }
    }
}

const cookieConsentLanguage = {
    default: "de",
    autoDetect: "browser",
    translations: {
        de: {
            consentModal: {
                title: "Mmmh, Cookies...",
                description: "Ja, auch auf dieser Seite gibt es sie. Wir verwenden Cookies für die Funktionen dieser Website, sowie zu Marketingzwecken. Hier kannst du einstellen, welche du zulassen möchtest - und welche nicht.",
                acceptAllBtn: "Alle akzeptieren",
                acceptNecessaryBtn: "Alle ablehnen",
                showPreferencesBtn: "Einstellungen verwalten",
                footer: "<a href=\"https://www.codista.com/de/datenschutz/\">Datenschutz</a>\n<a href=\"https://www.codista.com/de/impressum/\">Impressum</a>"
            },
            preferencesModal: {
                title: "Cookie Einstellungen",
                acceptAllBtn: "Alle akzeptieren",
                acceptNecessaryBtn: "Alle ablehnen",
                savePreferencesBtn: "Einstellungen speichern",
                closeIconLabel: "Modal schließen",
                serviceCounterLabel: "Optionen",
                sections: [
                    {
                        title: "Verwendung von Cookies",
                        description: "Lege hier fest, welche Arten von Cookies du zulassen möchtest."
                    },
                    {
                        title: "Notwendige Cookies <span class=\"pm__badge\">Immer Aktiviert</span>",
                        description: "Cookies die wir brauchen um diese Website korrekt darzustellen und User-spezifische Funktionen zu ermöglichen.",
                        linkedCategory: CAT_NECESSARY
                    },
                    {
                        title: "Analytics Cookies",
                        description: "Cookies die wir nutzen um das Userverhalten auf dieser Website auszuwerten.",
                        linkedCategory: CAT_ANALYTICS
                    },
                    {
                        title: "Marketing Cookies",
                        description: "Cookies die wir verwenden um Werbung und Marketing-Aktivitäten für User zu personalisieren.",
                        linkedCategory: CAT_ADVERTISEMENT
                    },
                    {
                        title: "Weitere Informationen",
                        description: "Bei Fragen betreffend Datenschutz kannst du uns jederzeit <a class=\"cc__link\" href=\"mailto:hello@codista.com\">via Email kontaktieren</a>."
                    }
                ]
            }
        }
    }
}

CookieConsent.run({
    // Trigger consent update when user choices change
    onFirstConsent: () => {
        updateGtagConsent();
    },
    onConsent: () => {
        updateGtagConsent();
    },
    onChange: () => {
        updateGtagConsent();
    },
    guiOptions: cookieConsentGUIOptions,
    categories: cookieConsentCategories,
    language: cookieConsentLanguage
})

const preferencesBtn = document.querySelector('#js-cookieconsent-preferences')
if (preferencesBtn) {
    preferencesBtn.addEventListener('click', () =>
        CookieConsent.showPreferences()
    )
}
