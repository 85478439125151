// Vanilla JS Module Index

// common modules, included on every page
// using standard ES6 imports here because no codesplitting intended
import './modules/navigation'
import './modules/cookieconsent'
import './modules/conversion'
import './modules/lazyloading'
import './modules/instantpage'
import './modules/lite-youtube'
import './modules/darkmode'
import './modules/eastereggs'
import './modules/tabs'
import './modules/details'

// JS Hooks have to be '.js-' prefixed classes/ids
const MODULES = {
    banner: '.js-banner',
    textfield: '.js-textfield',
    details: '.js-details',
    accordion: '.js-accordion',
    signup: '.js-signup',
    swiper: '.js-swiper',
    tabs: '.js-tabs',
    parallax: '.js-parallax',
    testimonials: '.js-testimonials',
    reactionPill: '.js-reaction-pill',
    copyToClipboard: '.js-copy-to-clipboard'
}
// Conditionally Import Modules based on DOM Nodes
const noop = () => {}
Object.keys(MODULES).forEach((name) => {
    if (document.querySelector(MODULES[name])) {
        import(`./modules/${name}`).then(noop)
    }
})
